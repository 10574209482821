<template>
  <div class="list-user">
    <div class="list-user-table">
      <div class="mb-2 row">
        <h2
          class="col-sm-12 col-md-6 col-lg-6 text-truncate-mess font-title font-weight-bold"
        >
          売上管理（日別）
        </h2>
        <div
          class="px-0 col-sm-12 col-md-6 col-lg-6 tex-left text-md-right text-sm-left"
        >
          <router-link
            :to="{
              name: $route.params.shopId
                ? 'listSaleManagementRanks'
                : 'listSaleManagementRanks domain',
            }"
          >
            <button class="mr-2 button-create-user">ランキング</button>
          </router-link>
          <button class="button-create-user" @click="downloadCSV()">
            CSV出力
          </button>
          <button
            v-on:click="gotoDashboard()"
            class="button-back-dashboard back-btn"
          >
            戻る
          </button>
        </div>
      </div>
      <div class="row position-relative">
        <div class="col-sm-12 col-md-6 col-lg-3">
          <p class="font-weight-bold">{{ year }}年{{ month }}月</p>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-auto">
          <p class="font-weight-bold">総計：{{ total_revenue }}円</p>
        </div>
      </div>
      <Tables
        :items="listSaleManagementDailyShow.data"
        :fields="fields"
        :itemsPerPageSelect="{}"
      >
        <template v-slot:status_order="{ item }">
          <td v-if="item.status_order == 0">未承認</td>
          <td v-else-if="item.status_order == 1">承認済み</td>
          <td v-else-if="item.status_order == 2">キャンセル</td>
        </template>
        <template v-slot:action="{ item }">
          <td class="py-2 pl-2 pr-1 d-flex justify-content-center">
            <div>
              <!-- <router-link
                :to="{
                  name: $route.params.shopId
                    ? 'detailSaleManagementDaily'
                    : 'detailSaleManagementDaily domain',
                  params: { shopId: $route.params.shopId, daily: item.date },
                  query: { total: item.total_revenue },
                }"
                class="d-flex flex-column align-items-center"
              > -->
              <CButton
                class="mx-1 btn-other"
                color="success"
                square
                variant="outline"
                size="sm"
                v-b-modal.modal-comfirm
                v-on:click="detail(item.date, item.total_revenue)"
              >
                日別詳細
              </CButton>
              <!-- </router-link> -->
            </div>
          </td>
        </template>
      </Tables>
      <div class="d-flex justify-content-center mt-4 mb-4">
        <b-button variant="secondary" class="mr-4" v-on:click="backMonth()"
          >前月へ</b-button
        >
        <b-button variant="secondary" class="mr-4" v-on:click="nextMonth()"
          >次月へ</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import Tables from "../../components/common/Tables";
import { tableFields } from "../../utils/table-fields.js";
import { mapActions, mapGetters } from "vuex";
import Encoding from "encoding-japanese";
import { Urls } from "../../utils/urls.js";
import { Api } from "../../utils/http-common.js";
import { Constants } from "../../utils/constants";
import moment from "moment";
export default {
  name: "listSaleManagementDailys",
  data() {
    return {
      fields: tableFields.SALE_MANAGEMENT_DAILY,
      listLimitRecordPage: [
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 200, text: "200" },
      ],
      daily: "",
      limit: 50,
      page: 1,
      year: 0,
      month: 0,
      messageError: {},
      messageResponse: "",
      messagerErr: "",
      messageSuccess: "",
      total_revenue: 0,
      dateSelect: "",
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      listSaleManagementDailyShow: [],
    };
  },
  components: {
    Tables,
  },
  created() {
    const date = this.$route.params.daily.split("-");
    this.year = date[0];
    this.month = date[1];
    this.getlistDetailSaleManagementDailys();
  },
  computed: {
    ...mapGetters(["listSaleManagementDaily", "message", "success", "error"]),
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["success", false]);
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
    listSaleManagementDaily() {
      let total = 0;
      this.json_data = this.listSaleManagementDaily.data;
      if (this.listSaleManagementDaily.data) {
        this.json_data.forEach((element) => {
          total += parseInt(element.total_revenue);
        });
      }
      this.total_revenue = Number(total).toLocaleString("ja");
      this.listSaleManagementDailyShow = this.listSaleManagementDaily;
    },
  },
  methods: {
    ...mapActions({ getSaleManagetmentDaily: "getSaleManagetmentDaily" }),
    ...mapActions({ blockUser: "blockUser" }),
    changeLang() {
      this.lang === "ja";
    },
    createUser() {
      this.$router.push({
        name: this.$route.params.shopId ? "create user" : "create user domain",
      });
    },
    showConfirm(id) {
      this.dataModal = id;
    },
    getlistDetailSaleManagementDailys() {
      const formData = {
        shop_id: this.shop_id,
        month: this.$route.params.daily,
        next: "",
        back: "",
      };
      this.getSaleManagetmentDaily(formData);
    },
    async csvExport(datas, dataRequest) {
      const url = `${Urls.ADMIN}/${Urls.SALES_MANAGEMENT}/${Urls.DAILY}/${Urls.EXPORT}`;
      const method = "POST";
      let response = await Api.adminRequestServer({
        method,
        url,
        data: dataRequest,
      });
      var { data } = response.data;
      if (data.success === false) return datas;
      datas += data.data;
      this.index++;
      if (this.index % Constants.LIMIT_CSV == 0) {
        const getDate = new Date();
        const nameExcel =
          "売上管理（日別）" +
          [
            getDate.getFullYear(),
            getDate.getMonth() + 1,
            getDate.getDate(),
          ].join("_") +
          " " +
          [getDate.getHours(), getDate.getMinutes(), getDate.getSeconds()].join(
            ":"
          );
        const CSVName = nameExcel + ".csv";
        let file = "対象月,販売件数,売上総計（円）\n";
        file += datas;
        datas = [];
        file = new Encoding.stringToCode(file);
        file = Encoding.convert(file, "sjis", "unicode");
        file = new Uint8Array(file);
        var fileURL = window.URL.createObjectURL(
          new Blob([file], { type: "text/csv;charset=sjis" })
        );
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", CSVName);
        document.body.appendChild(fileLink);
        fileLink.click();
      }
      return datas;
    },
    async downloadCSV() {
      this.messageError = {};
      this.messageResponse = "";
      this.messagerErr = "";

      try {
        this.index = 0;
        var datas = [];
        let dataRequest = {
          shop_id: this.shop_id,
          month: this.$route.params.daily,
        };
        datas = await this.csvExport(datas, dataRequest);
        if (Object.keys(datas).length == 0) {
          if (this.index == 0) {
            this.messageResponse = "データがありません";
          } else {
            this.messageSuccess = "ダウンロードが完了しました";
          }
          // $("body").removeClass("wait");
          return;
        }
        const getDate = new Date();
        const nameExcel =
          "売上管理（日別）" +
          [
            getDate.getFullYear(),
            getDate.getMonth() + 1,
            getDate.getDate(),
          ].join("_") +
          " " +
          [getDate.getHours(), getDate.getMinutes(), getDate.getSeconds()].join(
            ":"
          );
        const CSVName = nameExcel + ".csv";
        Math.floor(this.index / Constants.LIMIT_CSV) + 1 + ".csv";
        let file = "対象月,販売件数,売上総計（円）\n";
        file += datas;
        file = new Encoding.stringToCode(file);
        file = Encoding.convert(file, "sjis", "unicode");
        file = new Uint8Array(file);
        var fileURL = window.URL.createObjectURL(
          new Blob([file], { type: "text/csv;charset=sjis" })
        );
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", CSVName);
        document.body.appendChild(fileLink);
        fileLink.click();
        this.messageSuccess = "ダウンロードが完了しました";
        // $("body").removeClass("wait");
      } catch (e) {
        // $("body").removeClass("wait");
        this.messageResponse = "システムエラーが発生しました";
        console.log(e);
      }
    },
    changeStatus(value) {
      this.status_order = value;
    },
    gotoDashboard() {
      if (this.$route.params.shopId) {
        this.$router.push({ name: "Menu SaleManagerment" });
      } else {
        this.$router.push({ name: "Menu SaleManagerment domain" });
      }
    },
    nextMonth() {
      let getDateFomat = null;
      if (
        this.listSaleManagementDaily.data &&
        this.listSaleManagementDaily.data.length > 0
      ) {
        getDateFomat = this.listSaleManagementDaily.data[0].date;
      } else {
        getDateFomat = this.$route.params.daily + "-01";
      }
      const Datefomat = moment(getDateFomat).add(1, "M");
      const paramsDate = Datefomat.format("YYYY-MM");
      this.dateSelect = Datefomat.format("YYYY-MM-DD");
      const formData = {
        shop_id: this.shop_id,
        month: this.$route.params.daily,
        next: "next",
        back: "",
      };
      this.getSaleManagetmentDaily(formData);
      this.$router.push({
        name: this.$route.params.shopId
          ? "saleManagementdaily"
          : "saleManagementdaily domain",
        params: { daily: paramsDate },
      });
    },
    backMonth() {
      let getDateFomat = null;
      if (
        this.listSaleManagementDaily.data &&
        this.listSaleManagementDaily.data.length > 0
      ) {
        getDateFomat = this.listSaleManagementDaily.data[0].date;
      } else if (this.$route.params.daily === null) {
        getDateFomat = new Date();
      } else {
        getDateFomat = this.$route.params.daily + "-01";
      }
      const Datefomat = moment(getDateFomat).add(-1, "M");
      const paramsDate = Datefomat.format("YYYY-MM");
      const formData = {
        shop_id: this.shop_id,
        month: this.$route.params.daily,
        next: "",
        back: "back",
      };
      this.getSaleManagetmentDaily(formData);
      this.$router.push({
        name: this.$route.params.shopId
          ? "saleManagementdaily"
          : "saleManagementdaily domain",
        params: { daily: paramsDate },
      });
    },
    detail(date, total_revenue) {
      if (this.$route.params.shopId) {
        this.$router.push({
          name: "detailSaleManagementDaily",
          params: { shopId: this.$route.params.shopId, daily: date },
          query: { total: total_revenue },
        });
      } else {
        this.$router.push({
          name: "detailSaleManagementDaily domain",
          params: { daily: date },
          query: { total: total_revenue },
        });
      }
    },
  },
};
</script>
